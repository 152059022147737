
input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #007bff;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
  }
  
  input[type=file]::file-selector-button:hover {
    background: #007bff;
  }
  
  td, th{
  text-align: center;
  }
  td{
      font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;
  
  /* Tonal Shade */
  color: #6A808F !important;
  }
  th{
      font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;
  
  /* Tonal Blue */
  color: #062A42 !important;
  }
  .error {
      display: none !important;
  }
  
  .patient-list-lbl{
  /* Patient list */
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */
  text-decoration-line: underline;
  
  /* Tonal Blue */
  color: #062A42 !important;
  
  
  }
  
  .total-no-patient{
  /* Localised tumour: 2 patients */
  
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  text-align: right;
  
  color: #414141 !important;
  
  
  }
  
  .m-4.container1{
      margin: 3% !important;
  }
  
  .text-tonalblue, .text-tonalblue *{
      color: #062A42 !important;
  }
  
  .text-tonalblue-data{
  /* Group 62 */
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  
  color: #062A42 !important;
  
  
  }
  
  h5{
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 28px !important;
  line-height: 42px !important;
  }
  
  h4{
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 30px !important;
  line-height: 42px !important;
  }
  h3{
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 32px !important;
  line-height: 48px !important;
  }
  
  p.data{
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  }
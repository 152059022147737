td, th{
    text-align: center;
    }
    td{
        font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    text-align: center;
    
    /* Tonal Shade */
    color: #6A808F !important;
    }
    th{
        font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    text-align: center;
    
    /* Tonal Blue */
    color: #062A42 !important;
    }
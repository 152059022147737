.no-color{
    color: unset;
    text-decoration: unset;
}

.inline-flex{
    display: inline-flex
}

.cw-15{
    width: 25vh;
}

@media screen and (max-width: 500px) {
    .mobile-mt-2{
        margin-top: 2rem !important;
    }
}